import React from 'react';
import { useCurriculum } from './CurriculumContext';
import styled from 'styled-components';
import cambridgeLogo from '../../images/igcse_logo.png';
import edexcelLogo from '../../images/edexcel_logo.png';
import { Link } from 'react-router-dom';


// Styled Components
const PageWrapper = styled.div`
  background: ${({ theme }) => theme.colorsTwo.primary};
  min-height: 100vh;
  padding: 20px 40px; // Add horizontal padding
`;



const Heading = styled.h2`
  font-size: 2em; // Increase the size
  color: ${({ theme }) => theme.colorsTwo.primary}; // Navy blue
  margin-bottom: 40px; // Spacing after the title
`;

const Card = styled.div`
  background-color: white; // Navy blue
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
  max-width: 250px; // Set a maximum width for the cards
  margin: 0 auto 40px auto; // Center the cards and add a 20px gap below each card

  &:hover {
    transform: scale(1.05);
  }
`;

const CardLogoCambridge = styled.img`
  // width: 80px;
  height: 100px;;
  margin-bottom: 20px;
`;

const CardLogoEdexcel = styled.img`
  width: 160px;
  height: 100px;
  margin-bottom: 20px;
`;

const CurriculumCardContainer = styled.div`
  background-color: ${({ theme }) => theme.colorsTwo.tertiary};
  padding: 60px 0; // Added some padding to the top and bottom
  max-width: 800px; // Example value, adjust as needed
  margin: 0 auto; // Center the container
  border-radius: 20px; // This will round the corners
`;



// CurriculumCard Component
function CurriculumCard({ curriculum, logo, link, onClick }) {
  const LogoComponent = curriculum === "cambridge" ? CardLogoCambridge : CardLogoEdexcel;
  
  return (
    <Link to={link}>
      <Card onClick={onClick}>
        <LogoComponent src={logo} alt={`${curriculum} logo`} />
      </Card>
    </Link>
  );
}



// CurriculumSelection Component
function CurriculumSelection() {
  const { setCurriculum } = useCurriculum();

  return (
    <PageWrapper>
      <CurriculumCardContainer>
      <Heading>Select a Curriculum</Heading>

        <CurriculumCard 
          curriculum="cambridge"
          logo={cambridgeLogo}
          link="/chat/cambridge"
          onClick={() => setCurriculum('cambridge')}
        />
        <CurriculumCard 
          curriculum="edexcel"
          logo={edexcelLogo}
          link="/chat/edexcel"
          onClick={() => setCurriculum('edexcel')}
        />
      </CurriculumCardContainer>
    </PageWrapper>
  );
}


export default CurriculumSelection;
