import React from 'react';
import styled from 'styled-components';
import chatImage from '../images/chat-image.png';

// Main container for the Features Section. This is flex by default, so it can layout its children side-by-side.
const FeaturesArea = styled.section`
background-color: ${props => props.theme.colorsTwo.tertiary};

`
const FeaturesAreaContainer = styled.div`
  padding: 0px 20px 20px 20px; // Padding around the content
  max-width: 1200px; // Maximum width for the container
  margin: 0 auto; // Center the container horizontally
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row; // Default to horizontal layout

  @media (max-width: 600px) {
    flex-direction: column; // Stack elements vertically on mobile
  }
`;

// Container for the image. It takes half the width of the FeaturesSectionContainer due to `flex: 1`.
const ImageContainer = styled.div`
  flex: 1;
  margin-right: 20px;

  @media (max-width: 600px) {
    margin-right: 0; // Remove margin on mobile
    margin-bottom: 20px; // Add bottom margin to separate from the features list
  }

  img {
    max-width: 100%;
    display: block; // Remove any unwanted margins
    margin: 0 auto; // Center the image horizontally on mobile
  }
`;

const DescriptionContainer = styled.div`
  flex: 1;
  margin-left: 20px;

  @media (max-width: 600px) {
    margin-left: 0; // Remove margin on mobile
  }
`;

// Title for the features list.
const FeatureTitle = styled.h2`
  font-size: 28px; // Font size relative to the base font size
  margin: 0;
  padding-bottom: 10px;
  text-align: center; // Centering the title
  color: ${props => props.theme.colorsTwo.primary} ;
  font-weight: bold;
`;


// Unordered list to display the features.
const FeatureList = styled.ul`
  list-style-type: none; // Remove default list styling
  padding-left: 20px; // Padding to the left to accommodate the tick mark
  text-align: left;
`;


// Individual feature item in the list.
const FeatureItem = styled.li`
  font-size: 1em;
  margin-bottom: 10px;
  color: ${props => props.theme.colorsTwo.primary};
  position: relative; // Making the li relative to position the tick mark

  &::before { // Pseudo-element for the tick mark
    content: "✔"; // Unicode tick mark
    position: absolute;
    left: -20px; // Positioning the tick mark to the left of the list item
    color: ${props => props.theme.colorsTwo.primary}; // Match the tick mark color to your theme
  }
`;


// Styled component for the feature title inside the list. 
const FeatureName = styled.span`
  font-weight: 500; // Making it bold
  font-size: 1.25em;
  color: ${props => props.theme.colorsTwo.primary}; // You can adjust this color as you like
  // text-indent: -160px; // This should match the padding-left value of FeatureItem
  display: inline-block;
  `;

const FeatureDescription = styled.span`
  font-weight: 400;
  color: #6A7B89;
  line-height: 1.5;     // Increased line spacing for readability
`;



const featuresData = [
  {
    title: "Curriculum-centered:",
    description: "Tailored to the Edexcel and Cambridge International GCSE specification ensuring relevant assistance."
  },
  {
    title: "Scalable for Schools:",
    description: "Effortlessly onboard students, making AI-driven education accessible to all."
  },
  {
    title: "24/7 Availability:",
    description: "Your AI tutor is always on, ready to help anytime, anywhere."
  },
  {
    title: "Future-Ready Learning:",
    description: "Be at the forefront of education by leveraging cutting-edge AI technology."
  },
  {
    title: "Cost-Effective:",
    description: "Quality tutoring at a fraction of the cost of traditional methods."
  }
];


function FeaturesSection() {
  return (
    <FeaturesArea>
      <FeaturesAreaContainer>
        <FeatureTitle>Benefits</FeatureTitle> 
        <ContentContainer> 
          <ImageContainer>
            <img src={chatImage} alt="User interacting with the chat" />
          </ImageContainer>
          <DescriptionContainer>
            <FeatureList>
              {featuresData.map((feature, index) => (
                <FeatureItem key={index}>
                  <FeatureName>{feature.title}</FeatureName> <FeatureDescription>{feature.description}</FeatureDescription>
                </FeatureItem>
              ))}
            </FeatureList>
          </DescriptionContainer>
        </ContentContainer>
      </FeaturesAreaContainer>
    </FeaturesArea>
  );
}


export default FeaturesSection;
