// src/theme.js

const theme = {
    colorsTwo: {
      primary: '#2C3E50',
      secondary: '#ECF0F1', // Light gray
      tertiary: '#FAFAFA', // Off white
      accent: '#E74C3C',
    },
    font: 'IBM Plex Sans, sans-serif'
  };
  
  export default theme;
  