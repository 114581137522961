import React from 'react';
import Header from './Header/Header';
import Main from './Main/Main';
import Footer from './Footer/Footer';

function Landing() {
  return (
    <div>
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default Landing;
