import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../Landing/images/Mathcore_Logo.png';

const PaymentPage = styled.div`
    background-color: ${props => props.theme.colorsTwo.primary};
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Card = styled.div`
    background-color: ${props => props.theme.colorsTwo.tertiary};
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;

const Logo = styled.img`
    width: 100px;
    margin-bottom: 15px;  // Provide some space between the logo and the header
`;

const Text = styled.p`
    color: ${props => props.theme.colorsTwo.primary};
    margin: 20px 0;
`;

const LegalLink = styled.a`
    color: ${props => props.theme.colorsTwo.accent};

`;

const PaymentLink = styled.a`
    display: block;
    background-color: ${props => props.theme.colorsTwo.accent};
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    

`;

function PaymentPrompt() {
    const [hasAgreed, setHasAgreed] = useState(false);

    return (
        <PaymentPage>
            <Card>
                <LogoContainer>
                    <Logo src={logo} alt="Mathcore AI Logo" />
                </LogoContainer>
                <h1>Activate Subscription</h1>
                <Text>
                 Kindly note, activation of your AI Tutor access is done manually and might take a maximum of 12 hours.
                </Text>
                <div>
                    <input 
                        type="checkbox" 
                        checked={hasAgreed} 
                        onChange={() => setHasAgreed(prev => !prev)} 
                    />
                    <span>I agree to the </span>
                    <LegalLink href="/legal/terms-and-conditions">Terms and Conditions</LegalLink>
                    <span> and </span>
                    <LegalLink href="/legal/privacy-policy">Privacy Policy</LegalLink>
                </div>
                <PaymentLink 
                    href={hasAgreed ? "https://buy.stripe.com/cN216j1zK8cRgA8fYY" : "#"} 
                    disabled={!hasAgreed}
                >
                    Click here to pay and activate your account
                </PaymentLink>
            </Card>
        </PaymentPage>
    );
}


export default PaymentPrompt;
