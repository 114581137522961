import React, { useContext } from 'react'; 
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  
  // If the user isn't authenticated, redirect them to the login page
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // If the user hasn't paid, redirect them to the payment prompt page
  if (!currentUser.hasPaid) {
    return <Navigate to="/payment-prompt" />;
  }

  // If the user is authenticated and has paid, but tries to access the '/chat' route directly, redirect them to the curriculum selection
  if (children.type.name === 'Chat' && (!children.props.curriculum)) {
    return <Navigate to="/curriculum" />;
  }

  return children;
};



export default ProtectedRoute;

