import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';


// Main container for the Pricing Section.
const PricingSectionContainer = styled.section`
  background-color: ${props => props.theme.colorsTwo.tertiary};
  display: flex;
  justify-content: space-between;
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: ${props => props.theme.colorsTwo.primary};
  flex-wrap: wrap; // Allow wrapping of the flex items
  
  @media (max-width: 600px) {
    flex-direction: column; // Stack vertically for mobile
  }
`;

// Each individual pricing card.
const PricingCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #e0e0e0;
  background-color: ${(props) => props.bgColor || '#ffffff'};
  margin: 10px; // Add some spacing around each card
  
  @media (max-width: 600px) {
    margin-left: 0; // No left margin for mobile
    margin-right: 0; // No right margin for mobile
  }
`;


// Title section of the card.
const CardHeader = styled.h3`
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.colorsTwo.primary};
`;

// Subheading section of the card.
const CardSubheader = styled.p`
  font-size: 1.15em;
  margin: 10px 0;
`;

// Price or description section of the card.
const CardPrice = styled.p`
  font-size: 2em;
  font-weight: bold;
  color: ${(props) => props.theme.colorsTwo.accent};
`;

const CardPriceMessage = styled.p`
  font-size: 18px;
  font-weight: 400;
  // color: ${(props) => props.theme.colorsTwo.accent};
  color: #E74C3C;
  padding-bottom: 1rem;
`

// Benefits or features section of the card.
const CardFeatures = styled.ul`
  list-style-type: none;
  padding-left: 20px;
  margin: 20px 0;
  font-size: 1em;
`;

const FeatureItem = styled.li`
  font-size: 1.1em;
  margin-bottom: 10px;
`;

const buttonStyles = css`
  font-family: ${props => props.theme.font};
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 20px;
  text-decoration: none;
  margin-right: 2rem;
  transition: background-color 0.3s;

  &:hover {
    transform: scale(1.05);
  }
`;

const ContactUsButton = styled(Link)`
  ${buttonStyles}
  background-color: ${props => props.theme.colorsTwo.tertiary};
  color: ${props => props.theme.colorsTwo.primary};
  border: 2px solid ${props => props.theme.colorsTwo.primary}; // Border color set to primary
  font-weight: bold;
  
    
  &:hover {
    background-color: white; 
  }
  
  
  margin: 0rem;
  margin-top: auto;  // Pushes the button to the bottom of the flex container
`;

const SignUpButton = styled(Link)`
  ${buttonStyles}
  background-color: ${props => props.theme.colorsTwo.accent};
  color: ${props => props.theme.colorsTwo.tertiary};
  font-size: 1em;
  font-weight: bold;
  &:hover {
    background-color: #D43F00;
  }
  margin: 0rem;
  margin-top: auto;  // Pushes the button to the bottom of the flex container

`;


// Features for the "Individual Learner" card.
const individualFeatures = [
  "Guided AI Learning",
  "Comprehensive coverage",
  "Personalised feedback and guidance",
  "Safe and secure platform"
];

// Features for the "Institutional Plan" card.
const institutionalFeatures = [
  "Easy and scalable student onboarding",
  "Guidance aligned with curriculum standards",
  "Complements Classroom Teaching",
];

// Main component for the Pricing Section.

function PricingSection() {
    return (
      <PricingSectionContainer>
        {/* Individual Learner Card */}
        <PricingCard bgColor="#ECF0F1" style={{marginRight:'10px'}}>
          <CardHeader>Individual Learner</CardHeader>
          <CardSubheader>For dedicated students and lifelong learners.</CardSubheader>
          <CardPrice >£100/month</CardPrice>
          <CardFeatures>
            {individualFeatures.map((feature, index) => (
              <FeatureItem key={index}>{feature}</FeatureItem>
            ))}
          </CardFeatures>
          <SignUpButton to="login">Start Learning Now</SignUpButton>
          {/* <CardCTA>Start Learning Now</CardCTA> */}
        </PricingCard>
  
        {/* Institutional Plan Card */}
        <PricingCard bgColor="#2C3E50" style={{marginLeft:'10px'}}>
          <CardHeader style={{color:'#FAFAFA'}}>Institutional Plan</CardHeader>
          <CardSubheader style={{color:'#FAFAFA'}}>Empower your institution with AI-driven education.</CardSubheader>
          <CardPriceMessage style={{color:'#FAFAFA'}}>
            Customizable solutions tailored for educational institutions and businesses. Contact us for pricing details.
          </CardPriceMessage>
          <CardFeatures style={{color:'#ECF0F1'}}>
            {institutionalFeatures.map((feature, index) => (
              <FeatureItem key={index}>{feature}</FeatureItem>
            ))}
          </CardFeatures>
          <ContactUsButton to="enquiries">Contact Us</ContactUsButton>
        </PricingCard>
      </PricingSectionContainer>
    );
  }
  
  export default PricingSection;