// CurriculumContext.js
import React, { createContext, useState, useContext } from 'react';

const CurriculumContext = createContext();

export const CurriculumProvider = ({ children }) => {
  const [curriculum, setCurriculum] = useState(null);

  return (
    <CurriculumContext.Provider value={{ curriculum, setCurriculum }}>
      {children}
    </CurriculumContext.Provider>
  );
};

export const useCurriculum = () => {
  return useContext(CurriculumContext);
};
