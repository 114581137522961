import React from 'react';
import styled from 'styled-components';

const HeadlineArea = styled.section`
  background-color: ${props => props.theme.colorsTwo.tertiary};
`;

const HeadlineAreaContainer = styled.div`
  padding: 60px 20px;
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
`

const Headline = styled.h1`
  color: ${props => props.theme.colorsTwo.accent};
  font-size: 2.75em;
  font-weight: 500;
  margin-bottom: 20px;
`;

const Subheadline = styled.h2`
  color:${props => props.theme.colorsTwo.primary};
  font-weight: 400;
  font-size: 1.4em;
  max-width: 700px;
  margin: 0 auto;
`;

function HeadlineSection() {
    return (
      <HeadlineArea>
        <HeadlineAreaContainer>
          <Headline>Transforming Math learning with Artificial Intelligence</Headline>
          <Subheadline>From core concepts to challenging equations, experience step-by-step guidance tailored to your curriculum.</Subheadline>
        </HeadlineAreaContainer>
      </HeadlineArea>
    );
}

export default HeadlineSection;
