import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBv7cP8Ao8-jlPQ8pIiYsz4mjqTJvd-M3c",
  authDomain: "mathcore-academy.firebaseapp.com",
  projectId: "mathcore-academy",
  storageBucket: "mathcore-academy.appspot.com",
  messagingSenderId: "309886026687",
  appId: "1:309886026687:web:6de4040d22c5ddfc2dd70a",
  measurementId: "G-FTRBJT1T4T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);