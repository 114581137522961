import React from 'react';
import styled from 'styled-components';
import FeaturesSection from './FeaturesSection';
import HeadlineSection from './HeadlineSection';
import PricingSection from './PricingSection';

const MainContainer = styled.main`
  background-color: ${props => props.theme.colorsTwo.tertiary};

`;



function Main() {
    return (
      <MainContainer>
        <HeadlineSection />
        <FeaturesSection />
        <PricingSection />
      </MainContainer>
    );
  }
  

export default Main;
