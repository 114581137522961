import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LoginButton, SignUpButton } from '../../../components/Buttons';


const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 15px 20px; // Adjusted padding for better spacing
  background-color: ${(props) => props.theme.colorsTwo.primary};
  height: 80px;

  @media (max-width: 600px) {
    height: 70px;
    padding: 12px 10px; // Reduce padding on smaller screens
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  @media (max-width: 600px) {
    margin-left: 0.5rem;
  }
`;



const LogoText = styled.span`
  font-family: ${props => props.theme.font};
  font-weight: bold;
  color: ${props => props.theme.colorsTwo.tertiary};
  font-size: 36px;
  margin-left: 5px; // Adjusted margin to create a gap between the logo and text
  letter-spacing: 5.5px;
  
  @media (max-width: 600px) {
    font-size: 20px;
    letter-spacing: 3px; // Reduce letter spacing on mobile views
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;


function Header() {
  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <LogoText>Mathcore AI</LogoText>
        </Link>
      </LogoContainer>
      <ButtonContainer>
        <LoginButton to="/login">Login</LoginButton>
        <SignUpButton to="/login">Sign up</SignUpButton>
      </ButtonContainer>
    </HeaderContainer>
  );
}


export default Header;
