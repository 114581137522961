import React from 'react';
import styled from 'styled-components';
import sendMessageIcon from '../views/Landing/images/send-message.png';
import sendMessageIconActive from '../views/Landing/images/send-message-active.png';

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${props => props.theme.colorsTwo.tertiary};
`;

const Form = styled.form`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 55%;


`;

const InputArea = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  flex-grow: 1;
  border: 1px ${props => props.theme.colorsTwo.primary} solid;
  border-radius: 10px;
  background: white;
`;

const Input = styled.textarea`
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  resize: none;
  max-height: 200px;
  height: 15px;
  overflow: hidden;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const SendButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 8px;
  background-color: ${props => props.isActive ? props => props.theme.colorsTwo.primary : "transparent"};
`;

const SendButton = styled.button`
  background: url(${props => props.isActive ? sendMessageIconActive : sendMessageIcon}) no-repeat center;
  background-size: cover;
  width: 18px;
  height: 18px;
  border: none;
  cursor: pointer;
`;

function InputForm({ message, handleInputChange, handleSendClick, isLoading }) {
  const isMessageValid = message.length > 0 && message.length <= 500; // Assuming 500 is the character limit

  // The button should appear active only if the message is valid and the AI is not loading.
  const isActive = isMessageValid && !isLoading;

  return (
    <FormContainer>
      <Form onSubmit={handleSendClick}>
        <InputArea>
          <Input 
            placeholder="Ask a Math question" 
            value={message} 
            onChange={handleInputChange} 
          />
          <SendButtonWrapper isActive={isActive}>
            <SendButton 
              type="submit"
              isActive={isActive}
              disabled={!isActive} // Disabling button if loading or message is not valid
            />
          </SendButtonWrapper>
        </InputArea>
      </Form>
    </FormContainer>
  );
}

export default InputForm;
