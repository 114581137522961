import { useEffect } from 'react';

function PrivacyPolicy() {
    useEffect(() => {
        window.location.href = "https://pdfhost.io/v/TWhq0PIe3_PrivacyPolicy";
    }, []);

    return null; // Or you can return some loading or transition content if needed.
}

export default PrivacyPolicy;
