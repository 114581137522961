import React from 'react';
import styled from 'styled-components';
import { useForm, ValidationError } from '@formspree/react';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 1.5rem;
    font-weight: 600;
    width: 80%;
    font-family: ${props => props.theme.font};
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    margin-top: 0.3rem;
    font-family: ${props => props.theme.font};

    &:focus {
        border-color: ${props => props.theme.colorsTwo.primary};
        outline: none;
    }
`;

const StyledTextarea = styled.textarea`
    width: 100%;
    padding: 0.5rem 1rem;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    height: 100px;
    margin-top: 0.3rem;
    font-family: ${props => props.theme.font};
    resize: vertical; // Only allow vertical resizing
    min-height: 100px; // minimum height to which it can be resized

    &:focus {
        border-color: ${props => props.theme.colorsTwo.primary};
        outline: none;
    }
`;

const StyledButton = styled.button`
    background-color: ${props => props.theme.colorsTwo.primary};
    color: white;
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
    font-family: ${props => props.theme.font};

    &:hover {
        background-color: #1a2a3a; // Darker shade of the primary color
    }
`;

const EnquiryForm = () => {
    const [state, handleSubmit] = useForm("xzblrggg");
    
    if (state.succeeded) {
        return <p>Thank you for your enquiry! We will get back to you soon.</p>;
    }
    
    return (
        <Form onSubmit={handleSubmit}>
            <StyledLabel>
                Contact Name:
                <StyledInput type="text" name="name" required />
                <ValidationError prefix="Name" field="name" errors={state.errors} />
            </StyledLabel>
            <StyledLabel>
                Email:
                <StyledInput type="email" name="email" required />
                <ValidationError prefix="Email" field="email" errors={state.errors} />
            </StyledLabel>
            <StyledLabel>
                School/Organisation:
                <StyledInput type="text" name="school_organisation" />
                <ValidationError prefix="School/Organization" field="school_organisation" errors={state.errors} />
            </StyledLabel>
            <StyledLabel>
                Phone Number:
                <StyledInput type="tel" name="phone" />
                <ValidationError prefix="Phone" field="phone" errors={state.errors} />
            </StyledLabel>
            <StyledLabel>
                Message:
                <StyledTextarea name="message" required></StyledTextarea>
                <ValidationError prefix="Message" field="message" errors={state.errors} />
            </StyledLabel>
            <StyledButton type="submit" disabled={state.submitting}><b>Submit</b></StyledButton>
        </Form>
    );
};

export default EnquiryForm;
