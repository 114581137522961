import { useEffect } from 'react';

function TermsAndConditions() {
    useEffect(() => {
        window.location.href = "https://pdfhost.io/v/6CvK~WbRS_termsandcondition";
    }, []);

    return null; // Or you can return some loading or transition content if needed.
}

export default TermsAndConditions;