export const convertNewlinesToBreaks = (text) => {
    if (!text) return '';

    return text.split('\n').map((str, index, array) => 
        index === array.length - 1 ? str : <>
            {str}
            <br />
        </>
    );
}
