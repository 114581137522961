import React, { useState } from 'react';
import styled from 'styled-components';
import { postChatMessage } from '../api/API';
import InputForm from './InputForm';
import MessageArea from './MessageArea';
import { useCurriculum } from '../views/Curriculum/CurriculumContext';


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

function Chat() {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { curriculum } = useCurriculum();  // Retrieve the curriculum value


  const handleInputChange = (event) => {
    setMessage(event.target.value);
    event.target.style.height = 'inherit';
    const computed = window.getComputedStyle(event.target);
    const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                 + parseInt(computed.getPropertyValue('padding-top'), 10)
                 + event.target.scrollHeight
                 + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                 + parseInt(computed.getPropertyValue('border-bottom-width'), 10)
                 - 65;

    event.target.style.height = `${Math.min(height, 200)}px`;
  };

  const checkResponseFormat = (response) => {
    if (!response.data) {
      setError('Unexpected response format.');
    }
  }

  const updateChatHistory = (message, role = 'user') => {
    setChatHistory(prevChatHistory => [...prevChatHistory, { role, content: message }]);
    if (role === 'user') {
      setMessage('');
    }
  }

  const handleSendClick = async (event) => {
    event.preventDefault();
    setError(null); // clear the error message at the start of a new send attempt
    updateChatHistory(message);
    setIsLoading(true);

    try {
      const response = await postChatMessage(message, curriculum); // Pass the curriculum here
      setIsLoading(false); // Stop loading

      if (response.status >= 200 && response.status < 300) {
        checkResponseFormat(response);
        updateChatHistory(response.data.content, 'assistant');
        setError(null);
      } else {
        setError(response.data || 'Something went wrong. Please try again later.');
      }
    } catch (error) {
      setError('Something went wrong. Please try again later.');
      setIsLoading(false); // Stop loading

    }
  }

  return (
    <PageContainer>
      <MessageArea chatHistory={chatHistory} error={error} isLoading={isLoading} />
      <InputForm message={message} handleInputChange={handleInputChange} handleSendClick={handleSendClick} isLoading={isLoading} />
    </PageContainer>
  );
  
}

export default Chat;
