import React from 'react';
import styled from 'styled-components';
import { convertNewlinesToBreaks } from './HelperFunction';
import AIImage from '../views/Landing/images/Mathcore_Logo.png';
import UserImage from '../views/Landing/images/comment.png'; // Import the user image

const MessageAreaStyled = styled.div`
  flex-grow: 1;
  overflow-y: auto;  // allows vertical scrolling if needed
  overflow-x: hidden;  // prevents horizontal scrolling
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colorsTwo.tertiary};
  font-family: ${props => props.theme.font};
`;

const MessageContainer = styled.div`
  width: 100%; 
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;  
  background-color: ${props => (props.role === 'user' ? props.theme.colorsTwo.tertiary : props.theme.colorsTwo.secondary)};
  border-bottom: ${props => (props.role === 'AI' ? '3px solid #ddd' : 'none')};
`;

const MessageInnerContainer = styled.div`
  width: 55%; 
  padding: 10px;
  display: flex;
  align-items: flex-start;
  text-align: left;
`;

const UserMessage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-family: ${props => props.theme.font};
  color: ${props => props.theme.colorsTwo.primary}; // Deep blue color
`;

const AiMessage = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 
  font-family: ${props => props.theme.font};
  color: ${props => props.theme.colorsTwo.primary}; // Deep blue color
`;

const ImagePlaceholder = styled.div`
  width: 30px;
  height: 30px;
  background: url(${props => (props.role === 'user' ? UserImage : AIImage)}) no-repeat center;
  background-size: cover;
  margin-right: 10px;
`;

const ErrorMessage = styled.p`
  color: red;
`;

function MessageArea({ chatHistory, error, isLoading }) {
  return (
    <MessageAreaStyled>
      {chatHistory.map((message, index) => (
        <MessageContainer key={index} role={message.role}>
          <MessageInnerContainer>
            <ImagePlaceholder role={message.role} /> {/* Use the role prop to determine which image to display */}
            {message.role === 'user' ? (
              <UserMessage>
                {convertNewlinesToBreaks(message.content)}
              </UserMessage>
            ) : (
              <AiMessage>
                {convertNewlinesToBreaks(message.content)}
              </AiMessage>
            )}
          </MessageInnerContainer>
        </MessageContainer>
      ))}
      {isLoading && (
        <MessageContainer role="assistant">
          <MessageInnerContainer>
            <ImagePlaceholder role="assistant" />
            <AiMessage>
              Loading...
            </AiMessage>
          </MessageInnerContainer>
        </MessageContainer>
      )}
      {error && <ErrorMessage>Error: {error}</ErrorMessage>}
    </MessageAreaStyled>
  );
}

export default MessageArea;
