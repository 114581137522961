import React from 'react';
import styled from 'styled-components';

// Importing images for the footer
import companyLogo from '../images/Mathcore_Logo.png';
import linkedInIcon from '../images/linkedIn.png';
import email from '../images/email.png';
import whatsapp from '../images/whatsapp.png';


// Main Footer Container styling
const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme.colorsTwo.primary};  
  color: black;
  padding: 25px 60px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column; // Stack vertically for mobile view
    align-items: flex-start; // Left-align items for mobile view
    padding: 15px 20px; // Adjust padding for smaller screens
  }
`;

// Styling for individual sections within the footer
const FooterSection = styled.div`
  display: flex;
  flex-direction: column;  // Stacking content vertically within the section
  align-items: center;

  @media (max-width: 600px) {
    align-items: flex-start; // Left-align items for mobile view
  }
`;

// Styling for the company logo image
const LogoPlaceholder = styled.img`
  width: 60px; // Increased the size a bit for better visibility
  height: auto;
  margin-bottom: 2.5px;
  padding: 5px; // Padding to ensure it doesn't touch the edges or neighboring content
  background-color: ${(props) => props.theme.colorsTwo.primary}; // Ensure the logo has a matching background color
`;
const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;  // Stacking content vertically within the section
`

const ContactIcon = styled.img`
  width: 16px;
  height: auto;
  margin-right: 10px;  // Space between the icon and the text
  text-align: left !important;
`;

// Updated styling for footer items that combine an icon with a label (e.g., email, phone)
const ContactIconLabel = styled.p`
  display: flex;
  align-items: center;
  color: #ECF0F1;  // Light Grey
  font-size: 1em;  // Standard size
  font-weight: normal;  // Regular font weight
  margin: 5px 0;

`;



// Styling for Privacy Policy link
const PrivacyLink = styled.a`
  color: #ECF0F1; // Light Grey
  font-size: 1em; // Standard size
  font-weight: normal; // Regular font weight
  text-decoration: none; // Remove default underline
  margin: 5px 0;

  &:hover {
    color: #E74C3C; // Bright Coral on hover
  }

  @media (max-width: 600px) {
    // text-align: center; // Center text for mobile view
  }
`;

const SocialIconsContainer = styled(FooterSection)`
@media (max-width: 600px) {
  margin-top: 0.5rem;
}
`
// Styling for social media icons
const SocialIcon = styled.a`
  margin-bottom: 15px;
  font-size: 1.5em;
  color: inherit;
  text-decoration: none;
  position: relative; // relative positioning for the pseudo-element

  & > img {
    width: 24px;
    height: auto;
    transition: filter 0.3s; // smooth transition for hover effect
  }

  &:hover > img {
    // filter: brightness(0.55); // darken the image on hover
  }

  // Pseudo-element for color overlay
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0; // initially fully transparent
    transition: opacity 0.3s; // smooth transition for hover effect
    pointer-events: none; // ensures the pseudo-element doesn't interfere with hover/click events
  }

  &:hover::before {
    opacity: 0.7; // on hover, change the new colors opacity.
  }
`;


function Footer() {
  return (
    <FooterContainer>
      {/* Company Logo and Copyright */}
      <FooterSection>
        <LogoPlaceholder src={companyLogo} alt="Mathcore AI Logo" />
        <p style={{ color: "#ECF0F1" }}>© Mathcore AI Limited 2023</p> 
      </FooterSection>

     {/* Contact Information */}
        <ContactContainer>
            <ContactIconLabel>
                <ContactIcon src={email} alt="Email Icon" />
                    contact@mathcore.ai
            </ContactIconLabel>
            <ContactIconLabel>
                <ContactIcon src={whatsapp} alt="WhatsApp Icon" />
                    (+44) 79854 77505
              </ContactIconLabel>
        </ContactContainer>

      {/* Privacy Policy */}
      <FooterSection>
        <PrivacyLink href="/legal/privacy-policy">Privacy Policy</PrivacyLink>
        <PrivacyLink href="/legal/terms-and-conditions"> Terms and Conditions</PrivacyLink>
      </FooterSection>

      {/* Social Media Icons */}
      <SocialIconsContainer>
        {/* <SocialIcon href="https://twitter.com/mathcore">
          <img src={twitterIcon} alt="Twitter Icon" />
        </SocialIcon>
        <SocialIcon href="https://instagram.com/mathcore">
          <img src={instagramIcon} alt="Instagram Icon" />
        </SocialIcon> */}
        <SocialIcon href="https://linkedin.com/company/mathcore-ai">
          <img src={linkedInIcon} alt="LinkedIn Icon" />
        </SocialIcon>
      </SocialIconsContainer>
    </FooterContainer>
  );
}

export default Footer;
