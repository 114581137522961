import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const buttonStyles = css`
  font-family: ${props => props.theme.font};
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 20px;
  text-decoration: none;
  margin-right: 2rem;
  transition: background-color 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  white-space: nowrap; // Prevent wrapping of text in buttons
  margin-right: 2rem;

  @media (max-width: 600px) {
    font-size: 14px; // Reduced font size for mobile
    padding: 8px 16px; // Reduced padding for mobile
    border-width: 1px; // Thinner border for mobile
    margin-right: 1rem; // Reduce margin on smaller screens
  }

`;




const LoginButton = styled(Link)`
  ${buttonStyles}
  background-color: transparent;
  color: ${props => props.theme.colorsTwo.accent};
  border: 2px solid ${props => props.theme.colorsTwo.accent};

  &:hover {
    background-color: #34495E;
    border-color: #E5736F;
    color: #E5736F;
    transform: scale(1.00);
  }
`;

const SignUpButton = styled(Link)`
  ${buttonStyles}
  background-color: ${props => props.theme.colorsTwo.accent};
  color: ${props => props.theme.colorsTwo.tertiary};

  &:hover {
    background-color: #D43F00;
    transform: scale(1.00);
  }
`;

export { LoginButton, SignUpButton };
