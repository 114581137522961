import React from 'react';
import Header from '../Landing/Header/Header.jsx';
import styled from 'styled-components';
import EnquiryForm from './EnquiryForm';

const EnquiriesContainer = styled.div`
   background-color: ${props => props.theme.colorsTwo.primary}; // Using the primary color
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
`;

const EnquiriesContent = styled.div`
   background-color: ${props => props.theme.colorsTwo.tertiary}; // Using the off-white color
   width: 80%;
   padding: 2rem;
   border-radius: 10px;
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
   margin-top: 2rem;
`;

const EnquiriesPage = () => {
    return (
       <div>
          <Header />
          <EnquiriesContainer>
             <EnquiriesContent>
                <EnquiryForm />
                 {/* Rest of your Enquiries page content will go here */}
             </EnquiriesContent>
          </EnquiriesContainer>
       </div>
    );
 };


export default EnquiriesPage;
