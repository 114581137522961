/* eslint-disable no-unused-vars */

// React Imports
import React, { useEffect, useState } from 'react';

// Firebase Authentication Imports
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Firestore Imports
import { doc, getDoc } from "firebase/firestore";

// Import the initialized Firestore and Firebase instances
import { db } from "./Firebase";

// This creates a new React Context for authentication. Context provides a way to pass data through the component tree without having to pass props down manually at every level.
const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  // currentUser state keeps track of the current authenticated user. Initially, it's set to null, indicating no user is logged in.
  const [currentUser, setCurrentUser] = useState(null);

  // pending state is a flag to check if Firebase is still verifying the authentication state. Useful to avoid flickering of components during render.
  const [pending, setPending] = useState(true);

  // useEffect is a React hook that allows for side effects in function components. It runs after the component has been rendered.
  useEffect(() => {
    // This sets up an observer on the Auth object. The observer gets called whenever the user's sign-in state changes.
    const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        // If a user is authenticated, fetch their document from Firestore
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        // Check if the user's document exists in Firestore
        if (userDoc.exists()) {
          // If it does, set the currentUser state to include the user's authentication data along with the hasPaid field from Firestore
          setCurrentUser({
            ...user,
            hasPaid: userDoc.data().hasPaid
          });
        } else {
          // If the user's document doesn't exist, just set the currentUser with Firebase's user data.
          setCurrentUser(user);
        }
      } else {
        // If no user is authenticated, reset the currentUser state to null.
        setCurrentUser(null);
      }

      // Set pending to false after we've determined the user's authentication state and updated the currentUser state.
      setPending(false);
    });

    // The return statement in useEffect acts as a cleanup function. It gets called when the component is unmounted.
    // This ensures we don't have lingering listeners if the component that uses this hook gets unmounted.
    return unsubscribe;
  }, []);

  return (
    // AuthContext.Provider is a React component that allows consuming components to subscribe to context changes.
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Export the AuthContext to be used in other components and also export the AuthProvider which provides the context to its children.
export { AuthContext, AuthProvider };
