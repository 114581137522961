import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components'; 
import theme from './theme';
import PrivacyPolicy from './views/Legal/PrivacyPolicy'; 
import TermsAndConditions from './views/Legal/TermsAndConditions'; 
import Landing from './views/Landing/index';
import Chat from './Chat/Chat';
import Login from './views/Login/Login.jsx';
import ProtectedRoute from './authentication/ProtectedRoute';
import PaymentPrompt from './views/PaymentPrompt/index';
import { AuthProvider } from './authentication/AuthContext';
import Enquiries from './views/EnquiriesPage/index';
import CurriculumSelection from './views/Curriculum/CurriculumSelection';
import { CurriculumProvider } from './views/Curriculum/CurriculumContext'; // Import the CurriculumProvider

function App() {
  return (
    <ThemeProvider theme={theme}> 
      <AuthProvider>
        <CurriculumProvider>  {/* Wrap your Routes with CurriculumProvider */}
          <Router>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/curriculum" element={<ProtectedRoute><CurriculumSelection /></ProtectedRoute>} />
              <Route path="/chat/edexcel" element={<ProtectedRoute><Chat curriculum="edexcel" /></ProtectedRoute>} />
              <Route path="/chat/cambridge" element={<ProtectedRoute><Chat curriculum="cambridge" /></ProtectedRoute>} />
              <Route path="/chat" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
              <Route path="/login" element={<Login />} />
              <Route path="/payment-prompt" element={<PaymentPrompt />} />
              <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/legal/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/enquiries" element={<Enquiries />} />
            </Routes>
          </Router>
        </CurriculumProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
