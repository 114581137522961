import React from 'react';
import styled from 'styled-components';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { auth, googleProvider } from '../../authentication/Firebase';
import { useNavigate } from 'react-router-dom';
import logo from '../Landing/images/Mathcore_Logo.png';

const LoginPageContainer = styled.div`
    background-color: ${props => props.theme.colorsTwo.primary};
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LoginCard = styled.div`
    background-color: ${props => props.theme.colorsTwo.tertiary};
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Logo = styled.img`
    width: 100px;
    margin-bottom: 20px;
`;

const LoginHeader = styled.h1`
    font-family: ${props => props.theme.font};
    color: ${props => props.theme.colorsTwo.primary};
    margin-bottom: 20px;
`;

const FirebaseAuthContainer = styled.div`
    width: 100%; // Ensure Firebase Auth takes the full width of the card
    background-color: ${props => props.theme.colorsTwo.tertiary};
    padding: 0px;
    border-radius: 5px;

    .firebaseui-idp-google {
        border: 1px solid ${props => props.theme.colorsTwo.primary};
        color: ${props => props.theme.colorsTwo.primary};
        transition: all 0.3s ease;
      
        &:hover {
            background-color: ${props => props.theme.colorsTwo.tertiary};
        }

        .firebaseui-idp-icon {
            width: 20px;
            height: 20px;
        }
    }

    .firebaseui-idp-text-long {
        display: none;
    }
`;

const Login = () => {
    const navigate = useNavigate();

    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            googleProvider.providerId,
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => {
              navigate('/curriculum');
              return false;
            },
          },
    };

    return (
        <LoginPageContainer>
            <LoginCard>
                <Logo src={logo} alt="Mathcore AI Logo" />
                <LoginHeader>Access your account</LoginHeader>
                <FirebaseAuthContainer>
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
                </FirebaseAuthContainer>
            </LoginCard>
        </LoginPageContainer>
    );
};

export default Login;
